// Add root id to have higher CSS specificity for local overrides
#root {
  // TODO: Move these to SDS
  --anim-transition-default: cubic-bezier(0.65, 0.05, 0.36, 1) 200ms;

  .TextLink {
    &--primary {
      &.TextLink--underline {
        @media (hover: hover) {
          &:hover {
            --TextLink-color-text: var(--pal-text-link);
          }
        }
      }
    }

    &--secondary {
      &.TextLink--underline {
        @media (hover: hover) {
          &:hover {
            --TextLink-color-text: var(--pal-text-primary);
          }
        }
      }
    }
  }

  min-width: 365px;
  overflow-x: auto !important;

  // Generic styles
  .align {
    &--center {
      text-align: center;
    }

    &--right {
      text-align: right;
    }
  }

  .Landing-container {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Landing-buttons-wrapper {
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > a {
      margin-bottom: 2rem;
    }
  }

  .WalletButtons-container {
    width: 100%;
    max-width: 590px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 3rem 0;

    & > div {
      margin-bottom: 1.5rem;
      width: 100%;

      @media (min-width: 700px) {
        width: calc(50% - 1.75rem);
      }
    }
  }

  .Layout {
    &__header {
      @media (min-width: 600px) {
        --Layout-header-padding-vertical: 1rem;
      }

      @media (min-width: 700px) {
        --Layout-header-padding-vertical: 3rem;
      }

      &--center {
        @media (max-width: 900px) {
          justify-content: center;
          flex-grow: 1;
          min-width: auto;
          order: 0;
        }

        @media (max-width: 700px) {
          min-width: 100%;
          order: 1;
        }
      }
    }

    &__inset {
      flex-wrap: wrap;
    }
  }

  .Header {
    &__account {
      display: flex;
      order: 1;
      justify-content: center;
      margin-top: 1.2rem;
      min-width: 100%;
      position: relative;

      @media (min-width: 700px) {
        min-width: auto;
        align-items: center;
        order: 0;
        flex: 1;
        justify-content: flex-end;
        margin-top: 0;
        margin-right: 0.5rem;

        &::after {
          content: "";
          display: block;
          width: 1px;
          height: 2rem;
          background-color: var(--pal-border-primary);
          margin-left: 1rem;
        }
      }
    }
  }

  .FooterButtons-stacked {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;

    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .CopyKey-container {
    margin-top: 2.5rem;
    margin-bottom: 2.8rem;
  }

  .ReceiveModal__share {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    max-width: 19rem;

    &__qrcode {
      margin-bottom: 2rem;
      background-color: var(--pal-background-secondary);
      padding: 1rem;
      border-radius: 0.125rem;
    }

    .CopyKey-container {
      margin-top: 1.5rem;
    }
  }

  .SendTransaction__memo-link {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .Congestion {
    font-weight: var(--font-weight-medium);

    &--low {
      color: var(--pal-success);
    }

    &--medium {
      color: var(--pal-warning);
    }

    &--high {
      color: var(--pal-error);
    }
  }

  .TransactionHistory {
    &__header {
      margin-bottom: 2rem;

      &__note {
        span {
          display: block;
          margin-bottom: 1rem;
        }
      }

      &__refresh {
        display: block;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      @media (min-width: 900px) {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        h2 {
          margin-bottom: 1rem;
        }

        span {
          display: inline-block;
          margin-right: 0.5rem;
          margin-bottom: 0;
        }

        &__refresh {
          margin-bottom: 0;
        }
      }
    }

    &__memo {
      span {
        max-width: 12rem;
      }
    }
  }

  .TableContainer {
    margin-top: 2rem;
    position: relative;
    overflow: auto;

    .Table {
      td:first-child {
        z-index: 1;
      }

      span {
        display: block;
      }
    }
  }

  .TableNoteContainer {
    margin-top: 1rem;
  }

  .Table__cell--align--right {
    display: flex;
    justify-content: flex-end;
  }

  .DataSection {
    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  .ClaimableBalances {
    .Table {
      --table-min-width: 500px;
      width: auto;
    }
  }

  .NotFoundPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .Eyebrow {
      color: var(--pal-brand-primary);
    }
  }
}
