.WalletModalContent {
  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__logo {
      width: 3rem;
      height: 3rem;
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        height: 100%;
        fill: var(--pal-text-primary);
      }
    }
  }
}
