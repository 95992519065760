.WalletButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  min-width: 250px;

  &__button {
    flex: 1;
    width: 100%;
    border: 1px solid var(--pal-border-primary);
    border-radius: 0.25rem;
    background-color: var(--pal-background-primary);
    padding: 0.75rem;
    margin-right: 0.875rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: border-color var(--anim-transition-default);

    @media (hover: hover) {
      &:hover {
        border-color: var(--pal-border-secondary);
      }
    }

    svg {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.75rem;
      fill: var(--pal-text-primary);
    }
  }

  &__label {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--pal-text-primary);
    padding-top: 0.1875rem;
  }

  .DetailsTooltip {
    width: 100%;
  }
}
